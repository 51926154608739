import { Box, Container } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import {
  binancelogo,
  Bybitlogo,
  coinbase,
  okx_logo,
  uniswaplogo,
} from "../image";
const partenrarray = [
  {
    img: uniswaplogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: coinbase,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: binancelogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: Bybitlogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: okx_logo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: uniswaplogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: coinbase,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: binancelogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: Bybitlogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: okx_logo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: uniswaplogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: coinbase,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: binancelogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: Bybitlogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: okx_logo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: uniswaplogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: coinbase,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: binancelogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: Bybitlogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: okx_logo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: uniswaplogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: coinbase,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: binancelogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: Bybitlogo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  {
    img: okx_logo,
    height: "60px",
    marginTop: "0px",
    fsize: "150px",
  },
  
 
];

const Partner = () => {
  return (
    <Box
      sx={{
        // backdropFilter: "blur(10px)",
        // backgroundColor: "rgba(143, 204, 253, 0.9)",
        background: "rgba(255, 255, 255, 0.30)",
        // my: { xs: 10, md: 12 },
        py: 2,
      }}
    >
      <Marquee pauseOnHover="true">
        {partenrarray.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                width: { xs: item.fsize, sm: "150px", md: "200px" },
                // height: "50px",
                marginRight: 7,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: item.marginTop,
                // border: "2px solid red",
              }}
            >
              <img
                src={item.img}
                alt=""
                srcSet=""
                style={{
                  width: "100%",
                  height: item.height,

                  objectFit: "contain",
                }}
              />
            </Box>
          );
        })}
      </Marquee>
    </Box>
  );
};

export default Partner;
