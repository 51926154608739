import { Box, Hidden, Typography } from "@mui/material";
import React from "react";
import PresaleSection from "./PresaleSection";
import Belunomics from "./Tokenomics";
import HowToBuy from "./HowToBuy";
import Footer from "./Footer";
import DifferenceBelugana from "./DifferenceBelugana";
import Partner from "../components/Partner";

const Home = () => {
  return (
    <Box sx={{ boxSizing: "border-box" }}>
      <PresaleSection />
      <Partner />
      <DifferenceBelugana />
      <Belunomics />
      <HowToBuy />

      <Footer />
    </Box>
  );
};

export default Home;
