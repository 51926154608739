import { Box, Typography, styled } from "@mui/material";
import moment from "moment-timezone";
import { useState, useEffect } from "react";
import { StyledBox } from "./StyleBox";

const DownCounter = ({ time }) => {
  // const targetDate = moment.tz("2024-11-04T09:00:00", "Europe/London");
  const targetDateLondon = moment.tz("2024-10-22T17:00:00", "Europe/London");
  const targetDateUTC = targetDateLondon.utc();
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());
  function getTimeRemaining() {
    const now = moment();
    const duration = moment.duration(targetDateUTC.diff(now));
    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: "0px 10px",
        }}
      >
        <Box sx={{ border: "4px solid #88A4FE", borderRadius: "14px" }}>
          <StyledBox>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "25px", sm: "28px" },
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {timeRemaining?.days}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Days
            </Typography>
          </StyledBox>
        </Box>
        <Box sx={{ border: "4px solid #88A4FE", borderRadius: "14px" }}>
          <StyledBox>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "25px", sm: "28px" },
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {timeRemaining?.hours < 10
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  "0" + timeRemaining?.hours
                : timeRemaining?.hours}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Hours
            </Typography>
          </StyledBox>
        </Box>
        <Box sx={{ border: "4px solid #88A4FE", borderRadius: "14px" }}>
          <StyledBox>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "25px", sm: "28px" },
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {timeRemaining?.minutes < 10
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  "0" + timeRemaining?.minutes
                : timeRemaining?.minutes}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Min
            </Typography>
          </StyledBox>
        </Box>
        <Box sx={{ border: "4px solid #88A4FE", borderRadius: "14px" }}>
          <StyledBox>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "25px", sm: "28px" },
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {timeRemaining?.seconds < 10
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  "0" + timeRemaining?.seconds
                : timeRemaining?.seconds}
            </Typography>
            <Typography
              sx={{
                color: "#fff",

                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Sec
            </Typography>
          </StyledBox>
        </Box>
      </Box>
    </>
  );
};

export default DownCounter;
