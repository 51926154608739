import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { bg89 } from "../image";

const DifferenceBelugana = () => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: { xs: "650px", sm: "1000px", md: "750px" },
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          {/* Video Element */}
          <video
            src={bg89}
            autoPlay
            loop
            muted
            controls={false}
            playsInline // Added for iOS support
            style={{
              width: "100%",
              height: "100%",
              objectFit: matches ? "cover" : "cover",
              opacity: 0.6, // Adjust the opacity of the video
            }}
          />
          {/* Overlay for better text visibility */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.4)", // Semi-transparent black overlay
              zIndex: 1, // Keep this behind the text but in front of the video
              boxSizing: "border-box",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mx: "auto",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            boxSizing: "border-box",
            zIndex: 2, // Text on top of the overlay
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mx: "auto",
              width: "100%",
              height: "100%",
              boxSizing: "border-box",
              // position: "absolute",
            }}
          >
            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              sx={{ mt: { xs: 4, md: 0 }, mx: "auto", boxSizing: "border-box" }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "15px ", sm: "20px", md: "25px" },
                  color: "#fff",
                  textAlign: "center",
                  pb: 4,
                  fontFamily: "CCMaladroit",
                }}
              >
               Differences Between Belugana and Other Presales and Roadmap
              </Typography>
              <Box
                sx={{
                  // p: 3,
                  borderRadius: "20px",
                  py: { xs: 3, md: 8 },
                  boxSizing: "border-box",
                  mx: "auto",
                  width: { xs: "100%", sm: "100%" },
                  background: "rgba(255, 255, 255, 0.1)", // Semi-transparent background to see the blur
                  backdropFilter: "blur(10px)", // Apply the blur effect
                  WebkitBackdropFilter: "blur(10px)", // For Safari support
                  transition: "all 0.3s ease-in-out", // Smooth transition
                  "&:hover": {
                    boxShadow: "0px 8px 16px rgba(255,255,255, 0.2)", // Light box shadow on hover
                    transform: "scale(1.05)", // Slight scale effect
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "14px", md: "17px" },
                      color: "#fff",
                      textAlign: "center",
                      px: 2,
                      fontFamily: "CCMaladroit",
                    }}
                  >
                   The existing issue with presale meme coins is that their token prices tend to drop below the prices at which you purchased them during the presale after listing on DEX. BELUGANA will do everything possible to ensure that the token price does not fall below what you paid during the presale. Additionally, our token price is fixed, providing all investors with a fair opportunity. Additionally, the claim button should be available for the buyer to click immediately after purchase. BELUGANA has currently completed listing negotiations with one Tier 1 CEX. However, due to NDA restrictions, we cannot disclose specific details, so please refer to the exchanges listed on the BELUGANA website. The presale will end without prior notice. The end of the presale indicates that fundraising has been completed, and the announcement for the Tier 1 CEX listing is just around the corner!
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "15px" },
                  color: "#fff",
                  maxWidth: "md",
                  mx: "auto",
                  textAlign: "center",
                  pt: 4,
                  px: 2,
                  fontFamily: "CCMaladroit",
                }}
              >
                Belugana advises you not to sell on DEX, but the responsibility
                for your investment lies entirely with you, and Belugana assumes
                no liability
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default DifferenceBelugana;
