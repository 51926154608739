import { Height } from "@mui/icons-material";
import { TextField, InputAdornment } from "@mui/material";

const inputStyle = {
  "& input::-webkit-outer-spin-button,\n input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: "0",
  },
  width: "100%",
  "& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
    color: "#fff",
  },
  "& .css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
    color: "#fff",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#f0f0f0", // Off-white placeholder color
  },

  "& .MuiOutlinedInput-root": {
    // background: "#fff",
    "& fieldset": {
      border: "3px solid #fff",
    },
    "&:hover fieldset": {
      border: "3px solid #fff",
    },
    "&.Mui-focused fieldset": {
      border: "3px solid #fff",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#253A47", // Change this to the desired placeholder color
    },
    color: "#fff",
    fontFamily: "Poppins",
    height: "45px",
    borderRadius: "10px",
    background: "transparent",
  },
};

const CustomInput = (props) => {
  return <TextField {...props} sx={inputStyle} size="small" />;
};

export default CustomInput;

export const CustomField = ({
  placeholder,

  // amount,
  image,
  readOnly,
  value,
  // name,
  onChange,
}) => {
  return (
    <CustomInput
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      // name={name}
      value={value}
      InputProps={{
        readOnly,
        endAdornment: (
          <InputAdornment position="start">
            {image ? (
              <img
                src={image}
                className="iconTransform"
                alt="logo"
                width="30px"
                style={{
                  isolation: "isolate",
                  filter: "brightness(1)",
                }}
              />
            ) : (
              ""
            )}

            {/* <Box display="flex" alignItems="center" gap={1} color="#fff"> */}
            {/* <Typography>{amount ?? ""}</Typography> */}
            {/* <Typography>{currency?.toUpperCase() ?? ""}</Typography> */}
            {/* </Box> */}
          </InputAdornment>
        ),
      }}
    />
  );
};
