import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { bg1, logo, solidProofLogo } from "../image";
import BuyCard from "../components/BuyCard";

const PresaleSection = () => {
  return (
    <Box
      id="home"
      sx={{
        backgroundImage: `url(${bg1})`,
        backgroundSize: { xs: "cover", md: "cover" },
        backgroundRepeat: "no-repeat",
        backgroundPosition: { xs: "left", md: "center" },
        // height: "100vh",
        width: "100%",
        pb: 4,
      }}
    >
      <Container>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: 17,
            fontFamily: "CCMaladroit",
          }}
        >
          <Grid item xs={12} md={6.6} sx={{}}>
            <Box
              sx={{
                width: "auto",
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
                gap: "10px 0px",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  background: "#4466AD",
                  px: 2,
                  py: 1,
                  borderRadius: "10px",
                  width: { xs: "135px", md: "135px" },
                  border: "1px solid #fff",
                  fontFamily: "CCMaladroit",
                  fontWeight:"300",
                  fontSize:"12px",
                  textAlign:"center"
                }}
              >
                Coming soon
              </Typography>
              <Box
                sx={{
                  border: "1px solid #fff",
                  background: "#4466AD",
                  px: 2,
                  py: 1,
                  borderRadius: "10px",
                  width: { xs: "170px", md: "160px" },
                }}
              >
                <img
                  src={solidProofLogo}
                  alt=""
                  srcSet=""
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </Box>
            </Box>
            <Box
              sx={{ width: { xs: "280px", sm: "350px", md: "500px" }, pt: 6 }}
            >
              <img
                src={logo}
                alt=""
                srcsSt=""
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <BuyCard />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PresaleSection;
