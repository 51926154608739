import { Box, Container, Typography } from "@mui/material";
import React from "react";

const HowClaim = () => {
  return (
    <Container>
      <Typography
        sx={{
          fontSize: { xs: "20px", sm: "30px", md: "40px " },
          textAlign: "center",
          color: "#fff",
          py: { xs: 4, sm: 7, md: 10 },
          fontFamily: "CCMaladroit",
        }}
      >
        How to Claim $BELU
      </Typography>
      <Box
        sx={{
          // p: 3,
          borderRadius: "20px",
          boxSizing: "border-box",
          mx: "auto",
          width: { xs: "100%", sm: "100%" },
          background: "rgba(255, 255, 255, 0.1)", // Semi-transparent background to see the blur
          backdropFilter: "blur(10px)", // Apply the blur effect
          WebkitBackdropFilter: "blur(10px)", // For Safari support
          transition: "all 0.3s ease-in-out", // Smooth transition
          //   my: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "250px",
            gap: "20px 0px",
            p: { xs: 2, md: 5 },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "12px", sm: "14px", md: "17px" },
              color: "#fff",
              textAlign: "center",
              px: 2,
              fontFamily: "CCMaladroit",
            }}
          >
            After purchasing, click the claim button. Then, copy the token CA
            from the widget and import the token in your wallet (Base Mainnet).
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default HowClaim;
