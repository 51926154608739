import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";

import { WagmiConfig } from "wagmi";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
// import { mainnet, sepolia } from "./wagmiChainId";
import { base, baseSepolia } from "wagmi/chains";
const projectId = "cba73ada547c01c1a64d7725fb732495";
const chains = [base];
export const wagmiConfig = defaultWagmiConfig({ chains, projectId });

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "dark",
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiConfig config={wagmiConfig}>
    <BrowserRouter>
      <CssBaseline />
      <App />
    </BrowserRouter>
  </WagmiConfig>
);

reportWebVitals();
