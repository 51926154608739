import { Box, Container, Grid, Hidden, Typography } from "@mui/material";
import React from "react";
import TecnomicTable from "../components/TecnomicTable";
import TokenomicsChart from "../components/TokenomicsChart";
import TecMobileChart from "../components/TecMobileChart";
import { tokenBg } from "../image";

const Tokenomics = () => {
  const array = [
    {
      value: "Presale",
      text: "60%",
      color: "#5775CF",
      volume: "60 Billion",
    },
    {
      value: "Liquidity",
      text: "10%",
      color: "#9FE080",
      volume: "10 Billion",
    },
    {
      value: "Ecosystem",
      text: "10%",
      color: "#FFDC60",
      volume: "10 Billion",
    },

    {
      value: "Marketing",
      text: "20%",
      color: "#FF7070",
      volume: "20 Billion",
    },
  ];
  return (
    <>
      <Box
        sx={{
          position: "relative",
          backgroundImage: `url(${tokenBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%", // Ensure full height coverage if needed
        }}
      >
        {/* Overlay for better readability */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.6)", // Semi-transparent overlay
            zIndex: 1,
          }}
        />

        <Container
          id="Belunomics"
          sx={{ py: 10, position: "relative", zIndex: 2 }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "30px", md: "40px " },
              textAlign: "center",
              color: "#fff",
              py: { xs: 4, sm: 7, md: 10 },
              fontFamily: "CCMaladroit",
            }}
          >
            Belunomics
          </Typography>

          <Hidden mdDown>
            <Typography
              sx={{
                color: "#fff",
                height: "45px",
                fontSize: { xs: "18px ", sm: "25px" },
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                fontWeight: 800,
                mx: "auto",
                px: 3,
                fontFamily: "CCMaladroit",
              }}
            >
              Total SUPPLY : 100,000,000,000
            </Typography>

            <TecnomicTable />
          </Hidden>

          <Box>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "30px 0",
              }}
            >
              <Grid item xs={12} md={5.8}>
                <TokenomicsChart />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  {array.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          gap: "30px 30px",
                          mb: 1,
                          borderRadius: "20px",
                          p: 1,
                          px: 3,
                          background: "rgba(255, 255, 255, 0.1)", // Semi-transparent background to see the blur
                          backdropFilter: "blur(10px)", // Apply the blur effect
                          WebkitBackdropFilter: "blur(10px)", // For Safari support
                          transition: "all 0.3s ease-in-out", // Smooth transition
                          "&:hover": {
                            boxShadow: "0px 4px 8px rgba(255,255,255, 0.2)", // Light box shadow on hover
                            // transform: "scale(1.05)", // Slight scale effect
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "20px",
                            height: "8px",
                            borderRadius: "7px",
                            background: item.color,
                            mt: "14px",
                            p: 1,
                          }}
                        />
                        <Box
                          sx={{
                            ml: "17px",

                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: "600px",
                              fontSize: { xs: "14px", sm: "18px" },
                              fontFamily: "CCMaladroit",
                            }}
                          >
                            {" "}
                            {item.value}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontFamily: "CCMaladroit",
                                fontSize: { xs: "14px", sm: "16px" },
                              }}
                            >
                              {item.text}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#fff",
                                fontFamily: "CCMaladroit",
                                fontSize: { xs: "14px", sm: "16px" },
                              }}
                            >
                              ({item.volume})
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Tokenomics;
