/* eslint-disable react/prop-types */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Typography } from "@mui/material";

const TecnomicTable = ({ nonIndividualUsers }) => {
  const TableArray = [
    {
      Category: "Presale",
      Persantage: "60%",
      Allocation: "60,000,000,000",
      // CirculationOnLaunch: "60 Billion",
    },
    {
      Category: "Liquidity",
      Persantage: "10%",
      Allocation: "10,000,000,000",
      // CirculationOnLaunch: "10 Billion",
    },
    {
      Category: "Ecosystem",
      Persantage: "10%",
      Allocation: "10,000,000,000",
      // CirculationOnLaunch: "10Billion",
    },
    {
      Category: "Marketing",
      Persantage: "20%",
      Allocation: "20,000,000,000",
      // CirculationOnLaunch: "20 Billion",
    },
  ];
  return (
    <>
      <Box
        sx={{
          overflowX: "auto",
          overflowY: "auto",
          //   maxHeight: "600px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <TableContainer
          sx={{
            "& .MuiTableCell-root": {
              border: "none",
            },
          }}
        >
          <Table
            sx={{
              minWidth: 700,
              "& .MuiTableCell-root": {
                border: "1px solid #C49CFA",
                // my: 2,
              },
            }}
            aria-label="simple table"
          >
            <TableHead
              sx={
                {
                  // background: "#F6F8FC",
                }
              }
            >
              <TableRow sx={{}}>
                <TableCell
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",

                    borderTop: "none !important",
                    borderLeft: "none !important",
                    borderRight: "none !important",
                    borderBottom: "3px solid #54CCA2 !important",
                    color: "#fff",
                    height: "30px",
                    width: "70px",
                    borderRadius: "0px 0px 0px 0px",
                    fontFamily: "CCMaladroit",
                  }}
                  align="center"
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1p5x",
                    fontWeight: "bold",
                    borderTop: "none !important",
                    borderLeft: "none !important",
                    borderRight: "none !important",
                    borderBottom: "3px solid #54CCA2 !important",
                    color: "#fff",
                    width: "120px",
                    fontFamily: "CCMaladroit",
                  }}
                  align="center"
                >
                  Percentage
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    borderTop: "none !important",
                    borderLeft: "none !important",
                    borderRight: "none !important",
                    borderBottom: "3px solid #54CCA2 !important",
                    color: "#fff",
                    width: "120px",
                    fontFamily: "CCMaladroit",
                  }}
                  align="center"
                >
                  Allocation
                </TableCell>
                {/* <TableCell
                  sx={{
                    color: "#fff",
                    width: "160px",
                    borderTop: "none !important",
                    borderLeft: "none !important",
                    borderRight: "none !important",
                    borderBottom: "3px solid #54CCA2 !important",
                  }}
                  align="center"
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      fontFamily: "CCMaladroit",
                    }}
                  >
                    Circulating on Launch
                  </Typography>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody sx={{ color: "#fff" }}>
              {TableArray &&
                TableArray?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      border: "none !important",
                      color: "#fff",
                      "&:last-child td, &:last-child th": {},
                    }}
                  >
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{
                        borderTop: "1px solid #2F3B52",
                        borderLeft: "none !important",
                        borderRight: "none !important",
                        fontSize: "17px",
                        color: "#fff !important",
                        fontWeight: "600",
                        height: "30px",
                        width: "80px",
                        fontFamily: "CCMaladroit",
                      }}
                    >
                      {row?.Category}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderTop: "1px solid #2F3B52",
                        borderLeft: "none !important",
                        borderRight: "none !important",
                        fontSize: "17px",
                        color: "#fff !important",
                        fontWeight: "600",
                        height: "30px",
                        width: "80px",
                        fontFamily: "CCMaladroit",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "17px",
                          fontWeight: "bold",
                          fontFamily: "CCMaladroit",
                        }}
                      >
                        {row?.Persantage}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        borderTop: "1px solid #2F3B52",
                        borderLeft: "none !important",
                        borderRight: "none !important",
                        fontSize: "13px",
                        color: "#fff !important",
                        fontWeight: "600",
                        height: "30px",
                        width: "80px",
                        fontFamily: "CCMaladroit",
                      }}
                    >
                      {/* {row.bitcoin1} */}
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "17px",
                          fontWeight: "300",
                          fontFamily: "CCMaladroit",
                        }}
                      >
                        {row?.Allocation}
                      </Typography>
                    </TableCell>
                  
                  </TableRow>
                ))}
              <TableRow
                sx={{
                  borderTop: "3px solid #D7D7D7 !important",
                  borderLeft: "none !important",
                  borderRight: "none !important",
                  borderBottom: "none !important",
                  color: "#fff",
                  "&:last-child td, &:last-child th": {},
                }}
              >
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{
                    border: "none !important",
                    fontSize: "15px",
                    color: "#fff !important",
                    fontWeight: "bold",
                    height: "30px",
                    width: "80px",
                    // textDecoration: "underline",
                    fontFamily: "CCMaladroit",
                  }}
                >
                  Total
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: "none !important",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "bold",
                      fontFamily: "CCMaladroit",
                    }}
                  >
                    100%
                  </Typography>
                </TableCell>

                <TableCell
                  align="center"
                  sx={{
                    border: "none !important",
                  }}
                >
                  {/* {row.bitcoin1} */}
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "bold",
                      fontFamily: "CCMaladroit",
                    }}
                  >
                  100,000,000,000
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default TecnomicTable;
