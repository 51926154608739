import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Parent from "./pages/Parent";
import About from "./pages/About";
import { useNetwork, useSwitchNetwork } from "wagmi";

const App = () => {
  const { chain } = useNetwork();

  const { chains, switchNetwork } = useSwitchNetwork();
  useEffect(() => {
    window.scrollTo(0, 0);

    try {
      if (chain?.id === 8453) {
        return;
      } else {
        switchNetwork(8453);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [chain?.id, chains, switchNetwork]);

  return (
    <Box>
      <Routes>
        <Route path="/" element={<Parent />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
        </Route>
      </Routes>
    </Box>
  );
};

export default App;
