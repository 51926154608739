import React, { useCallback, useEffect, useState } from "react";
import Hidden from "@mui/material/Hidden";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { Link as ScrollLink } from "react-scroll";
import { Button, IconButton, Paper, Typography } from "@mui/material";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";

import { logo } from "../image";
import { erc20ABI, useAccount, useConnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const IconArray = [
  {
    icon: <TelegramIcon sx={{ color: "#506DAE" }} />,
    link1: "https://t.me/beluganaico",
  },
  {
    icon: <XIcon sx={{ color: "#506DAE" }} />,
    link1: "http://x.com/BeluganaCoin",
  },
];
const array2 = [
  {
    name: "Home",
    link1: "home",
    offset: 50,
  },
  {
    name: "Belunomics",
    link1: "Belunomics",
    offset: 50,
  },
  {
    name: "How To Buy",
    link1: "howtoBuy",
    offset: -20,
  },
  {
    name: "FAQ'S",
    link1: "faq",
    offset: 50,
  },
];

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#0F131B",
    justifyContent: "flex-start",
  },
  hover: {
    "&:hover": {
      color: "#fff",
    },
  },
});

// eslint-disable-next-line react/prop-types
export default function Header({ children }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [state, setState] = useState({ left: false });
  const location = useLocation();
  const routeName = location.pathname;

  const downloadPDF = () => {
    const pdfUrl = "/HOW-TO-BUY.pdf"; // Adjust the path
    window.open(pdfUrl, "_blank");
  };

  // const [activeSection, setActiveSection] = useState("home");

  // const handleSetActive = (to) => {
  //   setActiveSection(to);
  // };

  const styledactivelink = ({ isActive }) => {
    return {
      textDecoration: "none",
      textTransform: "capitalize",
      padding: "10px",
      borderRadius: "5px",
      fontSize: "20px",
      color: isActive ? "#000" : "#fff ",

      display: "flex",
      gap: "20px",
      alignItems: "center",
      background: isActive ? "#fff" : "",
    };
  };

  // eslint-disable-next-line no-shadow
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {children}

      <Box
        onClick={() => {
          navigate("/");
          window.scrollTo(0, 0);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          // width: "100px",

          height: "70px",
          mx: "auto",
          mt: "50px",
        }}
      >
        <Box sx={{ mx: "auto", width: "70px" }}>
          <img
            src={logo}
            alt="logo"
            srcSet=""
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Box>
        <Typography
          sx={{
            color: "#fff",
            // letterSpacing: "1px",
            fontSize: { xs: "25px", sm: "20px" },
            fontWeight: "600",
            textAlign: "center",
            mt: 1,
            mb: 5,
            fontFamily: "CCMaladroit",
          }}
        >
          Belugana
        </Typography>
      </Box>
      <List sx={{ mt: 4, px: 2 }}>
        {routeName === "/" && (
          <>
            {array2.map((item, index) => {
              return (
                <Box key={index}>
                  <ScrollLink
                    onClick={toggleDrawer(anchor, false)}
                    to={item?.link1}
                    spy
                    smooth
                    offset={item.offset}
                    duration={0}
                    // onSetActive={handleSetActive}
                    style={{
                      textDecoration: "none",
                      textTransform: "capitalize",
                      padding: "10px",
                      borderRadius: "5px",
                      fontSize: "20px",
                      color: "#fff",

                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      background: "transparent",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        width: "100%",
                        pl: 5,
                        fontFamily: "CCMaladroit",
                      }}
                    >
                      {item?.name}
                    </Typography>
                  </ScrollLink>
                </Box>
              );
            })}
          </>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            gap: "0 10px",
          }}
        >
          {IconArray.map((item, index) => {
            return (
              <IconButton
                LinkComponent={Link}
                to={item?.link1}
                target="_blank"
                key={index}
                sx={{
                  background: "#FFFFFF",
                  "&:hover": {
                    background: "#eee",
                  },
                }}
              >
                {item.icon}
              </IconButton>
            );
          })}
        </Box>

        <Box
          sx={{
            mt: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0px 20px",
          }}
        >
          {address ? (
            <>
              <Button
                onClick={connectionHandler}
                variant="simplebtn"
                sx={{
                  fontSize: "13px",
                  background: "#fff",
                  borderRadius: "15px",
                  border: "1px solid transparent",
                  fontFamily: "CCMaladroit",
                  color: "#000",
                  "&:hover": {
                    border: "1px solid #fff",
                    color: "#fff",
                  },
                }}
              >
                Disconnect Wallet
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={connectionHandler}
                variant="simplebtn"
                sx={{
                  fontSize: "13px",
                  background: "#fff",
                  borderRadius: "15px",
                  border: "1px solid transparent",
                  fontFamily: "CCMaladroit",
                  color: "#000",
                  "&:hover": {
                    border: "1px solid #fff",
                    color: "#fff",
                  },
                }}
              >
                connect Wallet
              </Button>
            </>
          )}
        </Box>
      </List>
    </div>
  );

  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const connectionHandler = useCallback(() => {
    if (address) {
      return open();
    }
    open();
  }, [address, open]);
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Hidden lgUp>
              {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Box sx={{ position: "relative" }}>
                    <Box
                      sx={{
                        position: "fixed",
                        top: "0px",
                        left: "0px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",

                        p: 1,
                        boxShadow: "0px 0.5px 5px 0px rgba(255,255,255,0.50)",
                        background: "#035798",
                        zIndex: 100,
                      }}
                    >
                      <Box
                        onClick={() => {
                          navigate("/");
                          window.scrollTo(0, 0);
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "0px 5px",
                          isolation: "isolate",
                          // border: "2px solid red",
                        }}
                      >
                        <Box
                          sx={{
                            width: { xs: "35px", sm: "40px" },
                            mt: { xs: "4px", sm: "8px" },
                          }}
                        >
                          <img width="100%" src={logo} alt="logo" srcSet="" />
                        </Box>
                        <Typography
                          sx={{
                            color: "#fff",
                            // letterSpacing: "1px",
                            fontSize: { xs: "17px", sm: "25px" },
                            fontWeight: "600",
                            fontFamily: "CCMaladroit",
                            // mb: 0.45,
                          }}
                        >
                          Belugana
                        </Typography>
                      </Box>

                      <IconButton onClick={toggleDrawer(anchor, true)}>
                        <MenuIcon
                          sx={{
                            fontSize: "25px",
                            cursor: "pointer",
                            mr: 1,

                            color: "#fff",
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Paper>
                    <SwipeableDrawer
                      classes={{ paper: classes.paper }}
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      <Box
                        sx={{
                          height: "100vh",
                          width: "100%",
                          background: "#035798",
                        }}
                      >
                        {list(anchor)}
                      </Box>
                    </SwipeableDrawer>
                  </Paper>
                </React.Fragment>
              ))}
            </Hidden>
          </Box>
        </Box>
      </Box>
    </>
  );
}
