import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { color } from "echarts";
import { Padding } from "@mui/icons-material";

// Register necessary ECharts components
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

const TokenomicsChart = () => {
  const matches = useMediaQuery("(min-width:1000px)");
  const isXsScreen = useMediaQuery("(max-width:599px)");

  const [option, setOption] = useState({});

  useEffect(() => {
    // Configure the chart with white colors
    setOption({
      tooltip: {
        trigger: "item",
        textStyle: {
          fontFamily: "CCMaladroit",
          color: "#000", // Tooltip text color
        },
      },
      legend: {
        show: false,
        top: "0%",
        left: "center",
        textStyle: {
          fontFamily: "CCMaladroit",
          color: "#fff", // Legend text color set to white
          Padding: "20px",
        },
      },
      series: [
        {
          name: "Belugana",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff", // White border for pie sections
            borderWidth: 2,
            fontFamily: "CCMaladroit",
          },
          label: {
            show: true,
            position: "center",
            fontFamily: "CCMaladroit",
            color: "#fff", // Label text color set to white
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 20,
              fontWeight: "bold",
              fontFamily: "CCMaladroit",
              color: "#fff", // Emphasized label text color set to white
            },
          },
          labelLine: {
            show: false,
            lineStyle: {
              color: "#000",
              fontFamily: "CCMaladroit",
            },
          },
          // color: ["#4322ED","#6665FF",  "#7789EF", "#0871B9"],
          data: [
            { value: 60000000000, name: "Presale" },
            { value: 10000000000, name: "Liquidity" },
            { value: 10000000000, name: "Ecosystem" },
            { value: 20000000000, name: "Marketing" },
          
          ],
        },
      ],
    });
  }, [isXsScreen]);

  return (
    <ReactEChartsCore
      style={{
        width: matches ? "100%" : "100%",
        height: matches ? 520 : 320,
        margin: "auto",
        marginTop: "40px",
      }}
      echarts={echarts}
      option={option}
      notMerge
      lazyUpdate
    />
  );
};

export default TokenomicsChart;
