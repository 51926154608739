import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const faqArray = [
  {
    heading: "What is Belugana?",
    text: "Belugana is the story of a brave beluga that set off across the Pacific Ocean in search of freedom. That beluga returned as a wealthy whale thanks to $BELU. Now, you too can seize the opportunity to amass great wealth in the world of cryptocurrency with $BELU!",
  },
  {
    heading: "What is the BELUGANA presale?",
    text: "Through the BELUGANA presale, buyers can secure tokens at a much lower price than the listing price. Unlike traditional presales, the token price is fixed, and the key differentiator is that investors can directly influence the CEX listing price. With the listing on a top-tier CEX already confirmed, early investors are sure to see significant gains.",
  },
  {
    heading: "Where can I see the tokens I purchased?",
    text: "You can check the tokens you've purchased by connecting your wallet through the widget on the BELUGANA website!",
  },
  {
    heading: "How can I communicate with the Belugana team?",
    text: "Please contact CONTACT@BELUGANA.XYZ.",
  },
];

const Faq = () => {
  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 2,
        color: "#fff",
        p: 4,
      }}
    >
      <Container maxWidth="md" id="faq" sx={{ pb: 10 }}>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "30px", md: "40px " },
            color: "#fff",
            textAlign: "center",
            py: { xs: 4, sm: 7, md: 10 },
            fontFamily: "CCMaladroit",
          }}
        >
          FAQ
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "15px 0" }}>
          {faqArray.map((item, index) => {
            return (
              <Accordion
                sx={{
                  color: "#fff",
                  background: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
                  backdropFilter: "blur(10px)", // Apply the blur effect
                  WebkitBackdropFilter: "blur(10px)", // For Safari support
                  borderRadius: "10px", // Rounded corners
                  transition: "all 0.3s ease-in-out", // Smooth transition
                  "&:hover": {
                    boxShadow: "0px 4px 8px rgba(255, 255, 255, 0.2)", // Light box shadow on hover
                  },
                }}
              >
                <AccordionSummary
                  expandIcon="" // This line adds the arrow icon
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    sx={{
                      fontFamily: "CCMaladroit",
                      fontSize: { xs: "12px", md: "17px" },
                    }}
                  >
                    {item.heading}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: "1px solid #D6D6D6" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "10px", md: "14px" },
                      mt: 1,
                      fontFamily: "CCMaladroit",
                    }}
                  >
                    {item.text}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Container>
    </Box>
  );
};

export default Faq;
