import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Faq from "./Faq";
import { howtobuyBg } from "../image";
import HowToBuyStepper from "../components/HowToBuyStepper";
import HowClaim from "./HowClaim";

const howToBuyArray = [
  {
    no: "01",
    heading: "Connect Your Wallet",
    title:
      "Use the widget to connect your MetaMask or any other supported wallet to the website. You can easily purchase $BELU tokens using  ETH & USDC.",
  },
  {
    no: "02",
    heading: "Fund Your Wallet with Cryptocurrency",
    title:
      "Add your preferred cryptocurrency to your wallet. Once funded, you can proceed to purchase using the coins mentioned in Step 1.",
  },
  {
    no: "03",
    heading: "Purchase $BELU",
    title: "Enter the amount of $BELU you wish to purchase.",
  },
];

const HowToBuy = () => {
  return (
    <Box
      sx={{
        position: "relative", // Set position to relative for the overlay
        backgroundImage: `url(${howtobuyBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100%", // Ensure full height coverage if needed
      }}
    >
      {/* Overlay for better readability */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
          zIndex: 1,
        }}
      />
      <Container id="howtoBuy" sx={{ position: "relative", zIndex: 2 }}>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "30px", md: "40px " },
              textAlign: "center",
              color: "#fff",
              py: { xs: 4, sm: 7, md: 10 },
              fontFamily: "CCMaladroit",
            }}
          >
            How to Buy $BELU
          </Typography>
        </Box>
        <HowToBuyStepper />
        <HowClaim />
        {/* <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {howToBuyArray.map((item, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={5.8}
                md={3.8}
                sx={{ mt: { xs: 4, md: 0 } }}
                key={index}
              >
                <Box
                  sx={{
                    p: 2,
                    borderRadius: "20px",
                    py: 4,
                    height: { xs: "auto", sm: "290px", md: "270px" },
                    background: "rgba(255, 255, 255, 0.1)", // Semi-transparent background to see the blur
                    backdropFilter: "blur(10px)", // Apply the blur effect
                    WebkitBackdropFilter: "blur(10px)", // For Safari support
                    transition: "all 0.3s ease-in-out", // Smooth transition
                    "&:hover": {
                      boxShadow: "0px 8px 16px rgba(255,255,255, 0.2)", // Light box shadow on hover
                      transform: "scale(1.05)", // Slight scale effect
                    },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        background: "#BA8BF9",
                        height: "50px",
                        width: "50px",
                        borderRadius: "25px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                        mx: "auto",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "22px",
                          fontFamily: "CCMaladroit",
                        }}
                      >
                        {item.no}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "14px 0",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", md: "22px" },
                        color: "#fff",
                        textAlign: "center",
                        fontFamily: "CCMaladroit",
                      }}
                    >
                      {item.heading}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "12px", md: "17px" },
                        color: "#fff",
                        textAlign: "center",
                        fontFamily: "CCMaladroit",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid> */}
      </Container>
      <Faq />
    </Box>
  );
};

export default HowToBuy;
