import { Box, Container, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          gap: "10px 0",
          color: "#fff",
          py: 7,
        }}
      >
        <Typography
          sx={{ fontSize: { xs: "", md: "25px" }, fontFamily: "CCMaladroit" }}
        >
          DISCLAIMER
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", sm: "14px", md: "14px" },
            fontFamily: "CCMaladroit",
          }}
        >
          Cryptocurrency trading is highly volatile and carries a certain level
          of risk. It may not be suitable for all investors. Investors should
          exercise caution as they may lose some or all of their
          invested capital.
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", sm: "17px", md: "20px" },
            fontFamily: "CCMaladroit",
          }}
        >
          © 2024 Belugana — All Rights Reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default Footer;
