import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { StyledBox } from "./StyleBox";
import moment from "moment-timezone";
import { complete } from "../image";

const CountDownUp = () => {
  // const presaleStart = new Date("2024-10-15T17:20:00").getTime(); // Presale start date
  const presaleStart = moment.tz("2024-10-22T17:00:00", "Europe/London");
  const presaleEnd = new Date("2025-12-22T15:00:00").getTime(); // Presale end date

  const [timePassed, setTimePassed] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date().getTime(); // Current time
      const timeDifference = now - presaleStart; // Time passed since the presale started

      if (timeDifference > 0 && now < presaleEnd) {
        // Calculate the time passed in days, hours, minutes, and seconds
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        // Update the state with the new time passed
        setTimePassed({ days, hours, minutes, seconds });
      }
    };

    const interval = setInterval(updateTimer, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [presaleStart, presaleEnd]);

  return (
    <div>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: "10px 0", pb: 2 }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "0 27px",
            mx: "auto",
            justifyContent: "center",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "CCMaladroit",
                fontSize: "18px",
              }}
            >
              Arctic
            </Typography>
            <Typography
              sx={{
                // fontSize: "14px",
                color: "#01FF2B",
                fontFamily: "CCMaladroit",
                transform: "rotate(-20deg)",
                position: "absolute",
                top: "-25px",
                left: "-14%",
                translate: "transform(-0%, -0%)",
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
              }}
            >
              <Box sx={{ width: "30px", mx: "auto", mb: -1 }}>
                <img
                  src={complete}
                  alt=""
                  srcSet=""
                  style={{ width: "100%" }}
                />
              </Box>
              <span>Completed</span>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "CCMaladroit",
                fontSize: "18px",
                position: "relative",
              }}
            >
              Atlantic
            </Typography>
            <Typography
              sx={{
                // fontSize: "14px",
                color: "#01FF2B",
                fontFamily: "CCMaladroit",
                transform: "rotate(-20deg)",
                position: "absolute",
                top: "4px",
                right: { md: "110px", lg: "140px" },
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ width: "30px", mx: "auto", mb: -1 }}>
                <img
                  src={complete}
                  alt=""
                  srcSet=""
                  style={{ width: "100%" }}
                />
              </Box>
              <span>Completed</span>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "0 27px",
            mx: "auto",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ color: "#fff", fontFamily: "CCMaladroit", fontSize: "18px" }}
          >
            Antarctic
          </Typography>
          <Typography
            sx={{ color: "#fff", fontFamily: "CCMaladroit", fontSize: "18px" }}
          >
            Pacific
          </Typography>
          <Typography
            sx={{ color: "#fff", fontFamily: "CCMaladroit", fontSize: "18px" }}
          >
            Indian
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "2px",
            background: "#fff",
            width: { xs: "10%", sm: "25%", md: "10%" },
          }}
        />
        <Typography
          sx={{
            fontSize: "12px",
            color: "#fff",
            fontFamily: "CCMaladroit",
            textAlign: "center",
            textTransform: "lowercase",
            mb: 1,
          }}
        >
          The ultimate goal <br />
          to secure the top five best nests
        </Typography>
        <Box
          sx={{
            height: "2px",
            background: "#fff",
            width: { xs: "10%", sm: "25%", md: "10%" },
          }}
        />
      </Box>

      <Typography
        sx={{
          fontSize: "20px",
          color: "#fff",
          fontFamily: "CCMaladroit",
          textAlign: "center",
          mt: 2,
          mb: 1,
        }}
      >
        Presale Time Has Started
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: "0px 10px",
        }}
      >
        <Box sx={{ border: "4px solid #88A4FE", borderRadius: "14px" }}>
          <StyledBox>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "25px", sm: "28px" },
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {timePassed.days}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Days
            </Typography>
          </StyledBox>
        </Box>
        <Box sx={{ border: "4px solid #88A4FE", borderRadius: "14px" }}>
          {" "}
          <StyledBox>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "25px", sm: "28px" },
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {timePassed.hours}{" "}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Hours
            </Typography>
          </StyledBox>
        </Box>

        <Box sx={{ border: "4px solid #88A4FE", borderRadius: "14px" }}>
          <StyledBox>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "25px", sm: "28px" },
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {timePassed.minutes}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Min
            </Typography>
          </StyledBox>
        </Box>
        <Box sx={{ border: "4px solid #88A4FE", borderRadius: "14px" }}>
          <StyledBox>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "25px", sm: "28px" },
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {timePassed.seconds}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Sec
            </Typography>
          </StyledBox>
        </Box>
      </Box>
    </div>
  );
};

export default CountDownUp;
