import { Box, Hidden } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import Header from "../components/Header";

const Parent = () => {
  return (
    <Box sx={{ background: "#506DAE" }}>
      <Box>
        <Hidden lgDown>
          <Navbar />
        </Hidden>
        <Hidden lgUp>
          <Header />
        </Hidden>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Parent;
