import { readContract, writeContract } from "wagmi/actions";
import { waitForTransaction } from "@wagmi/core";
import { preSaleAddress } from "./environment";
import preSaleAbi from "./preSaleAbi.json";

// Read Write hooks
export const preSaleReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: preSaleAddress,
    abi: preSaleAbi,
    functionName,
    args,
  });
  return data;
};

export const preSaleWriteFunction = async (functionName, args, value) => {
  const { hash } = await writeContract({
    address: preSaleAddress,
    abi: preSaleAbi,
    functionName,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};
