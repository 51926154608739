import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import { Link as ScrollLink } from "react-scroll";
import { logo } from "../image";
import { erc20ABI, useAccount, useConnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const IconArray = [
  {
    icon: <TelegramIcon sx={{ color: "#506DAE" }} />,
    link1: "https://t.me/beluganaico",
  },
  {
    icon: <XIcon sx={{ color: "#506DAE" }} />,
    link1: "http://x.com/BeluganaCoin",
  },
];
const array2 = [
  {
    name: "Home",
    link1: "home",
    offset: 50,
  },
  {
    name: "Belunomics",
    link1: "Belunomics",
    offset: 50,
  },
  {
    name: "How To Buy",
    link1: "howtoBuy",
    offset: -20,
  },
  {
    name: "FAQ'S",
    link1: "faq",
    offset: 50,
  },
];

// const array4 = [
//   {
//     name: "How to Buy",
//     // link1: "/Donate_now",
//   },
// ];
export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeName = location.pathname;

  const [colorChange, setColorchange] = useState(false);

  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const connectionHandler = useCallback(() => {
    if (address) {
      return open();
    }
    open();
    const changeNavbarColor = () => {
      if (window.scrollY > 0.0000001 * window.innerHeight) {
        // Check if scroll value is greater than 100vh
        setColorchange(true);
      } else {
        setColorchange(false);
      }
    };

    window.addEventListener("scroll", changeNavbarColor);

    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, [address, open]);

  const styledactivelink = ({ isActive }) => {
    console.log("active", isActive);
    return {
      textDecoration: "none",
      textTransform: "capitalize",
      padding: "10px",
      borderRadius: "5px",
      fontSize: "12px",
      color: "#fff",
      alignItems: "center",
      fontWeight: "400",
    };
  };

  return (
    <>
      <Box sx={{ position: "relative", width: "100%", mx: "auto" }}>
        <Box
          sx={{
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "100%",
            boxSizing: "border-box",
            background: colorChange ? "transparent" : "#000106",
            backdropFilter: colorChange ? "blur(9px)" : "none",
            zIndex: 100,
          }}
        >
          <Container maxWidth="xl">
            <Box
              py={1.5}
              sx={{
                width: "80%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "transparent",
                mx: "auto",
              }}
            >
              <Box
                onClick={() => {
                  navigate("/");
                  window.scrollTo(0, 0);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "0px 15px",
                  // isolation: "isolate",
                  cursor: "pointer",
                }}
              >
                <img width="50px" src={logo} alt="logo" srcSet="" />
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "25px",
                    mt: 1,
                    ml: 2,
                    fontFamily: "CCMaladroit",
                  }}
                >
                  Belugana
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "0px 80px",
                  alignItems: "center",
                  borderRadius: "10px",
                }}
              >
                <Box>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {routeName === "/" && (
                      <>
                        {array2.map((item, index) => {
                          return (
                            <ScrollLink
                              key={index}
                              to={item.link1}
                              spy
                              smooth
                              offset={item.offset}
                              // duration={1000}
                              // onSetActive={handleSetActive}
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                                padding: "10px",
                                borderRadius: "5px",
                                fontSize: "17px",
                                color: "#fff",
                                alignItems: "center",
                                fontWeight: "400",
                                textTransform: "uppercase",
                                isolation: "isolate",
                                fontFamily: "CCMaladroit",
                              }}
                              className="cool-link"
                            >
                              {item.name}
                            </ScrollLink>
                          );
                        })}
                      </>
                    )}
                  </Stack>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "0 10px" }}>
                {IconArray.map((item, index) => {
                  return (
                    <IconButton
                      LinkComponent={Link}
                      to={item?.link1}
                      target="_blank"
                      key={index}
                      sx={{
                        background: "#FFFFFF",
                        "&:hover": {
                          background: "#eee",
                        },
                      }}
                    >
                      {item.icon}
                    </IconButton>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "0px 20px",
                  }}
                >
                  {address ? (
                    <>
                      <Button
                        onClick={connectionHandler}
                        variant="simplebtn"
                        sx={{
                          fontSize: "13px",
                          background: "#fff",
                          borderRadius: "15px",
                          border: "1px solid transparent",
                          fontFamily: "CCMaladroit",
                          color: "#000",
                          "&:hover": {
                            border: "1px solid #fff",
                            color: "#fff",
                          },
                        }}
                      >
                        Disconnect Wallet
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={connectionHandler}
                        variant="simplebtn"
                        sx={{
                          fontSize: "13px",
                          background: "#fff",
                          borderRadius: "15px",
                          border: "1px solid transparent",
                          fontFamily: "CCMaladroit",
                          color: "#000",
                          "&:hover": {
                            border: "1px solid #fff",
                            color: "#fff",
                          },
                        }}
                      >
                        connect Wallet
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};
